/* tailwind import */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-slate-100;
}

/**
 * Remove focus styles for non-keyboard focus.
 */
:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
} */

/* --------------------- */

/* @layer utilities {
  .hyphens-none {
    hyphens: none;
  }

  .hyphens-manual {
    hyphens: manual;
  }

  .hyphens-auto {
    hyphens: auto;
  }
} */
